import React from 'react'
import cn from 'classname';

const PageContainer = ({ children, className }) => {
  return (
    <div className={cn("container my-6 max-w-4xl px-8 md:px-12 pb-4 lg:pb-8 flex-grow", className)}>
      {children}
    </div>
  );
};

export default PageContainer;